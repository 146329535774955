<template>
  <div class="home">

    <h1>欢迎光临南北小店</h1>
    <div class="card">
      <h2>网页源代码成品html | js | php</h2>
      <h3>简单网页，本人制作，可支持部署和后期修改内容</h3>

      <div class="container">
        <div class="row">
          <div class="col-md-12  " style="display: flex; justify-content: center; align-items: center">

            <el-carousel indicator-position="outside" :autoplay="true" >
              <el-carousel-item v-for="(item,index) in images" :key="index" >
                <img :src="require('../assets/' + item.src)" alt="">
              </el-carousel-item>
            </el-carousel>


          </div>
        </div>



      </div>
    </div>

    <div class="card">
      <h2>GPT-Sovits音色模型</h2>
      <h3>可教程，远程部署，定制模型</h3>

      <div class="container">
        <div class="row">
          <div class="col-md-12"  style="display: flex; justify-content: center; align-items: center">
            <el-carousel indicator-position="outside" :autoplay="true" >
              <el-carousel-item v-for="(item,index) in models" :key="index" >
                <img :src="require('../assets/' + item.src)" alt="">
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>

   
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  components: {

  },
  data() {
    return {
      images: [
        { id:1, src: 'lunbo.png' },
        { id:2,src: 'lunbo2.png' },
        { id:3,src: 'lunbo3.png' },
        { id:4,src: 'lunbo4.png' },
        { id:5,src: 'lunbo5.png' },

      ],
      models: [
        { id:1, src: 'lunbo.png' },
        { id:2,src: 'lunbo.png' },
        { id:3,src: 'lunbo.png' },
        { id:4,src: 'lunbo.png' },
      ]
    }
  },
}
</script>
<style lang="less">
@media screen and (max-width: 768px) {
.card{
  h2{
    font-size: 16px;
  }
  h3{
    font-size: 14px;
  }
  width: 95%!important;
  .el-carousel {
    width: 100%!important;
  height: 250px !important;

}

.el-carousel__container{
  width: 100%!important;
  height: 210px !important;


}

.el-carousel__item{
  width: 100%!important;
  height: 210px !important;
}



  img{
    width: 100%!important;
  height: 210px !important;


  }

}
footer{
  .lianxi .lianxi-2 .icon {
  width: 24px;
    height: 24px;
    margin-left: 5px;
}
}


}
.lianxi .lianxi-2{
  cursor: pointer;
  margin: 10px 0;
  text-align: right;
}
.lianxi .lianxi-2 a{
  text-decoration: none;
  color: black;
}
.lianxi .lianxi-2 .icon{
  width: 40px;
  height: 40px;
  margin-left: 20px;
}
footer{
  width: 100%;
  height: 200px;
  background-color: rgb(221, 221, 221);
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
body{
}
.el-carousel {
  width: 1200px!important;
  height: 750px!important;
}

.el-carousel__container{
  width: 1200px;
  height: 675px!important;
}

.el-carousel__item{
  width: 1200px;
  height: 675px!important;
}

.el-carousel img{
  width: 1200px;
  height: 675px;
}


.card {
padding-top: 20px;
  background-color: rgba(255, 255, 255, 0.5)!important;
  border-radius: 10px!important;
  border: 0;
  width: 80%;
  height: auto;
  // min-height: 50vh;
  margin: 20px 0;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.311);

}

.home {
  padding: 20px 0;
  width: 100%;
  height: 95vh;

  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>