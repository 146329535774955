<template>
  <div id="app">

    <!--     
    <el-col :lg="6" :xs="8"><router-link to="/"><div class="grid-content bg-purple">首页</div></router-link></el-col>
      
    <el-col :lg="6" :xs="8"><router-link to="/htmlcode"><div class="grid-content bg-purple">HTML-php成品</div></router-link></el-col>
    <el-col :lg="6" :xs="8"><router-link to="/models"><div class="grid-content bg-purple">GPT-Sovits模型</div></router-link></el-col>
    <el-col :lg="6" :xs="6" hidden><div class="grid-content bg-purple"></div></el-col> -->

    <nav class="bg-purple fadein animation-duration-1000 ">
      <router-link to="/">
        <div class="grid-content">首页</div>
      </router-link>
    <router-link to="/htmlcode">
      <div class="grid-content">网页源代码成品</div>
    </router-link>
    <router-link to="/models">
      <div class="grid-content">语音合成模型</div>
    </router-link>

    </nav>
    <router-view></router-view>
    <!-- <component :is='footer'></component> -->
<footerCom></footerCom>
  </div>
</template>
<script>
import footerCom from './components/footer.vue'
export default {
  name: 'App',
  components: {
    footerCom
  },
  data() {
    return {

    }
  },
  created() {
  },
}
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}

nav a {
  color: black;
  text-decoration: none;
}



.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
  transition: .5s;

}

.bg-purple:hover {
  background: #e5e9f2;

}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
}
nav{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 8vh;
  font-weight: bold;
}
nav a{
  display: inline-block;
  margin: 0 10px;
  padding: 0 10px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
</style>
